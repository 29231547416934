
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "",
    "label": "Dashboards",
    "icon": "pi pi-chart-bar",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/devices/nav_dashboard",
        "label": "Consumo de energia",
        "icon": "pi pi-bolt",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/devices/nav_dashboard_gas",
        "label": "Consumo de gas",
        "icon": "pi pi-cloud",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/devices",
    "label": "Dispositivos",
    "icon": "pi pi-stopwatch",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/authtable",
    "label": "Usuarios",
    "icon": "pi pi-user",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/authtable/pruebaaut",
    "label": "menu aut",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  }
],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}